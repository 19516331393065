.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.75);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window.visible {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
  width: 400px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
  border: 1px solid #aaa;
  border-radius: 10px;
}

.modal-window header {
  font-weight: bold;
}

.modal-window span {
  /* font-size: 150%; */
  margin: 0 0 15px;
}


.modal-close {
  color: #aaa;
  line-height: 50px;
  /* font-size: 80%; */
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.cookiesContent {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px 70px;
}

.cookiesContent button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}

.cookiesContent img {
  width: 82px;
  margin-bottom: 15px;
}

.cookiesContent p {
  margin-bottom: 40px;
  font-size: 18px;
}

.cookiesContent button.accept {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  width: 200px;
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}