@import url(index.css);

.form__label {
  font-family: 'Roboto', sans-serif;
  margin-left: 0.3rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(-4.5rem);
}

.form__input {
  font-family: 'Roboto', sans-serif;
  color: #333;
  padding: 0.3rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: 2px solid var(--primary-color);
  width: 100%;
  display: block;
  /* border-bottom: 0.3rem solid transparent; */
  transition: all 0.3s;
  outline: none;
}

.form__input:disabled,
.form__input[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.select-box {
  caret-color: transparent;
}

.form__group ul {
  list-style-type: none;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #add8e6;
  max-height: 180px;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
    top: -30px;
    z-index: 10000;
    width: 100%;
}

.form__group ul li {
  padding: 7px 9px;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
  color: #6e6e6e;
}

.form__group ul li.selected {
  background-color: #e8e8e8;
  color: #333;
}


.form__input:placeholder-shown+.form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}