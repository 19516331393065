@import url('index.css');

.sub-menu-parent {
  position: relative;
}

.sub-menu {
  visibility: hidden;
  /* hides sub-menu */
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  transform: translateY(-2em);
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
}

.sub-menu-parent .sub-menu.visible {
  visibility: visible;
  /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  transform: translateY(18%);
  transition-delay: 0s, 0s, 0.3s;
  /* this removes the transition delay so the menu will be visible while the other styles transition */
}

/* presentational */

.sub-menu-parent span {
  color: var(--primary-color);
  background-color: var(--primary-bg);
  display: block;
  padding: 0.5em 1em;
  text-decoration: none;
  transition: .3s;
}

.sub-menu-parent span:hover {
  cursor: pointer;
  background: var(--secondary-color);
}

.sub-menu-parent ul,
.sub-menu-parent ul li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav>ul {
  background: var(--primary-bg);
  text-align: center;
}

nav>ul>li {
  display: inline-block;
  border-left: solid 1px var(--primary-bg);
}

.sub-menu-parent ul>li:first-child,
.sub-menu-parent ul>li:first-child>span,
.sub-menu-parent ul {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.sub-menu-parent ul>li:last-child,
.sub-menu-parent ul>li:last-child>span,
.sub-menu-parent ul {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sub-menu {
  background: var(--secondary-color);
}